import React, { useEffect, useState } from 'react';
import { Dropdown } from '@etiquette-ui/inputs-dropdowns';
import IconLink from 'components/IconLink';

import CardContainer from 'containers/CardContainer';
import ResponsiveCard from 'containers/ResponsiveCard';
import ListActionsContainer from 'pages/MenuItems/containers/ListActionsContainer';
import ListLayout from 'pages/MenuItems/containers/ListLayout';
import ResponsiveSpacer from 'pages/MenuItems/components/ResponsiveSpacer';
import ResponsiveTitleContainer from 'pages/MenuItems/containers/ResponsiveTitleContainer';
import FormTitle from 'pages/MenuItems/components/FormTitle';

import Table from 'components/Table';
import useCategories from 'state/categories';
import structure from './structure';
import useI18n from 'i18n/useI18n';

import {
  getTranslatedCategories,
  getProcessedCategoriesWithOptions,
} from 'pages/MenuItems/utils/transformers';

import { filterByParentCategory } from 'pages/MenuItems/utils/filters';
import DragHandle from 'components/Table/DragHandle';

const List = ({ location }) => {
  const { translate, selectedLang } = useI18n();

  const [data, setData] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [categories, dispatcher] = useCategories();

  useEffect(() => {
    const processed = getProcessedCategoriesWithOptions(categories);
    processed.splice(0, 1);

    const newOptions = getTranslatedCategories(processed, translate, selectedLang);

    if(newOptions.length === options.length) return;

    setOptions(newOptions);

    const defaultCategory = newOptions[0] || {};
    setSelectedCategory(defaultCategory);
  }, [categories.subcategories, selectedLang]);


  useEffect(() => {
    dispatcher.fetchStart();
  }, [])

  useEffect(() => {
    const filtered = filterByParentCategory(categories.subcategories, [selectedCategory]);
    setData(filtered);
  }, [categories.subcategories, selectedCategory])

  const onSort = ({ newIndex, oldIndex, commitMove }) => {
    const newData = [...data];
    const element = newData[oldIndex];
    let isSetAsFirst = false;

    if(newIndex === 0 && oldIndex > 0){
        isSetAsFirst = true;
    } else if(newIndex-1 < 0) return;
    
    const previousElement = newData[isSetAsFirst ? newIndex : newIndex-1];
    if(!isSetAsFirst && !previousElement.position) return;

    commitMove();

    dispatcher.position({
      id: element.id,
      position: newIndex + 1,
    });
  };

  return (
    <ListLayout>
      <ListActionsContainer>
        <IconLink to={'/menu/subcategories'}>{translate('Back to Menu Subcategories')}</IconLink>
        
        <ResponsiveTitleContainer>
          <FormTitle>{translate('Arrange')}</FormTitle>
        </ResponsiveTitleContainer>

        <div style={{ width: "50%", marginLeft: '15px' }}>
          <Dropdown
            labelProps={{ label: translate('Category') }}
            containerProps ={{
              styles: {
                maxWidth: '300px'
              }
            }}
            selectProps={{
              onChange: setSelectedCategory,
              options,
              placeholder: selectedCategory?.label || translate('Select a category'),
              value: selectedCategory              
            }}
          />
        </div>
        <div style={{ flex: 1 }}></div>
      </ListActionsContainer>
      <ResponsiveSpacer />
      <CardContainer>
        <ResponsiveCard>
          <Table
            items={data}
            structure={structure}
            config={{ translate }}
            onSortEnd={onSort}
            dragHandle={<DragHandle />}
            dragable={true}
          />
        </ResponsiveCard>
      </CardContainer>
    </ListLayout>
  );
};


export default List;
