import { default as BaseDialog } from 'components/CustomBaseDialog';
import { PrimaryButton, TextButton } from '@etiquette-ui/buttons';
import { PRIMARY, WRONG, PRIMARY_LIGHT, PRIMARY_DARK } from '@etiquette-ui/colors';
import { Dropdown, Input } from '@etiquette-ui/inputs-dropdowns';
import styled from 'styled-components';

export const DialogContainer = styled(BaseDialog)`
    .dialog-module_content__2NnHL {
        height: 100%;
        width: 100%;
    }

    .dialog-module_mainContent__3RV04 {
        margin: 0px;
        height: 100%;
    }
`;

export const Header = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
    border-bottom: solid 0.5px #d2d2d2;
    padding-bottom: 10px;
 
    @media( max-width: 768px ){
        grid-template-columns: 1fr;
    }
`;

export const Select = styled(Dropdown)`
    width: 60%;

    @media( max-width: 768px ){
        width: 60%;

    }
`;

export const PickersSection = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;

    @media( max-width: 768px ){
        margin-bottom: 15px;
    }
`;

export const SearchSection = styled.div`
    display: flex;
    align-items: center;
    & > div {
        width: 80%;
    }
`;

export const Content = styled.div`
    display: flex;
    padding: 10px;
    flex: 1;
    min-height: 0;
`;

export const ImagesContainer = styled(Content)`
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fit, 100px);
    grid-template-rows: repeat(100px);
    overflow: scroll;
    justify-content: center;
`;

export const ImageUploadFrame = styled.div`
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    border: 1px dashed ${({ dragging }) => (dragging ? `${WRONG}` : '#707070')};
    flex: 1;
`;

export const Image = styled.img`
    width: 100px;
    height: 100px;
    outline: solid ${({ selected }) => (selected ? `5px ${PRIMARY}` : '1px black')};
    cursor: pointer;
`;

export const Cancel = styled(TextButton)`
    flex: 1;
`;

export const Add = styled(PrimaryButton)`
    max-width: 150px;

    button: {
        background-color: ${PRIMARY_LIGHT};
    }
`;

export const Accept = styled(PrimaryButton)`
    flex: 1;

    button: {
        background-color: ${PRIMARY_DARK};
    }
`;

export const ActionsContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 10px;
    gap: 30px;

    @media( max-width: 768px ){
        gap: 15px;
    }
`;